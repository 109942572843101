import React, { FunctionComponent, useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { LoginPage } from './account/login/LoginPage';
import { RegisterPage } from './account/register/RegisterPage';
import { ForgotPasswordPage } from './account/forgotPassword/ForgotPasswordPage';
import { RegisterSuccessPage } from './account/register/RegisterSuccessPage';
import { RegisterConfirmRoute } from './account/register/RegisterConfirmRoute';
import FindEmailPage from './account/forgotPassword/FindEmailPage';
import { ResetPasswordPage } from './account/forgotPassword/ResetPasswordPage';
import { SuccessPasswordPage } from './account/forgotPassword/SuccessPasswordPage';
import { MyFilesPage } from './main/my_files/MyFilesPage';
import QuickGuidePage from './main/quick_guide/QuickGuidePage';
import { CreateStopfilePage } from './main/create_stopfile/CreateStopfilePage';
import { OrderLeadsPage } from './main/order_leads/OrderLeadsPage';
import { AdminDashboardPage } from './admin/AdminDashboardPage';
import { api_get_base_data } from '../api/endpoint/base_data/api_get_base_data';
import { OrderData } from '../data_layer/order/OrderData';
import { OrderManager } from '../data_layer/order/OrderManager';
import { UserManager } from '../data_layer/user/UserManager';
import { UserData } from '../data_layer/user/UserData';
import { ApiObjBaseData } from '../api/object/base_data/ApiObjBaseData';
import { TargetGroupEditPage } from './main/target_group/TargetGroupEditPage';
import { TargetGroupListPage } from './main/target_group/TargetGroupListPage';
import { PageFrameAccount } from './page_frame/PageFrameAccount';
import { PageFrameMain } from './page_frame/PageFrameMain';
import { AdminUsersListPage } from './admin/users_new/AdminUsersListPage';
import { AdminUserMainPage } from './admin/users_new/AdminUserMainPage';
import { AdminGlobalPricesPage } from './admin/global_prices/AdminGlobalPricesPage';
import { MyUserMainPage } from './main/my_user/MyUserMainPage';
import { AdminBlockedNumbersPage } from './admin/blocked_numbers/AdminBlockedNumbersPage';
import { PopulateFileUploadPage } from './main/populate_file/PopulateFileUploadPage';
import { PopulateFileOrderPage } from './main/populate_file/PopulateFileOrderPage';

let userManager: UserManager | undefined = undefined;
let orderManager: OrderManager | undefined = undefined;
let fetchDataStarted = false;

export const AppRouter: FunctionComponent = () => {

    // ========================================================================================
    // === Objects for all screens
    // ========================================================================================

    const [baseData, setBaseData] = useState <undefined|ApiObjBaseData> (undefined);
    const [userData, setUserData] = useState <UserData> (UserData.createNew());
    const [orderData, setOrderData] = useState <OrderData> (OrderData.createNew());
    
    // ======================================================================
    // === Base data
    // ======================================================================

    useEffect(() => {

        if (userManager === undefined) {
            userManager = new UserManager(actionNewUserData);
        }
        if (orderManager === undefined) {
            orderManager = new OrderManager(userManager, userData);
        }

        userManager.setCallbackNewUserData(actionNewUserData);
        orderManager.parentSetCallback(actionNewOrderData);

        if (!fetchDataStarted) {
            fetchDataStarted = true;
            api_get_base_data()
                .then((result) => {
                    setBaseData(result);
                    if (orderManager !== undefined) {
                        orderManager.parentSetBaseData(result);
                    }
                });
        }
    }, []);

    // ======================================================================
    // === Actions
    // ======================================================================

    const actionNewUserData = (userData: UserData) : void => {
        setUserData(userData);
        if (orderManager !== undefined) {
            orderManager.parentSetUserData(userData);
        }
    }

    const actionNewOrderData = (orderData: OrderData) : void => {
        setOrderData(orderData);
    }

    // ========================================================================================
    // === Render
    // ========================================================================================

    if (baseData == undefined || userManager === undefined || orderManager == undefined) {
        return (
            <div>Laddar...</div>
        );
    }

    return (
        <BrowserRouter>
            <Routes>

                {/* === ACCOUNT =================================== */}

                <Route 
                    path="account"
                    element={
                        <PageFrameAccount
                            userData={userData} />
                    }>

                    <Route
                        path="login"
                        element={
                            <LoginPage
                                userData={userData}
                                userManager={userManager} />
                        }
                    />

                    <Route
                        path="register"
                        element={
                            <RegisterPage />
                        }
                    />

                    <Route
                        path="register/success"
                        element={
                            <RegisterSuccessPage />
                        }
                    />

                    <Route
                        path="register/confirm/:token"
                        element={
                            <RegisterConfirmRoute />
                        }
                    />

                    <Route
                        path="reset_password"
                        element={
                            <ForgotPasswordPage />
                        }
                    />

                    <Route
                        path="reset_password/found_email"
                        element={
                            <FindEmailPage />
                        }
                    />

                    <Route
                        path="new_password"
                        element={
                            <ResetPasswordPage />
                        }
                    />

                    <Route
                        path="reset_password/success"
                        element={
                            <SuccessPasswordPage />
                        }
                    />

                </Route>

                {/* === ADMIN =================================== */}

                <Route 
                    path="admin"
                    element={
                        <PageFrameMain
                            baseData={baseData}
                            userData={userData}
                            userManager={userManager}
                            orderData={orderData}
                            orderManager={orderManager}
                        />
                    }>

                    <Route
                        path=""
                        element={
                            <AdminDashboardPage
                                userData={userData}
                            />
                        }
                    />

                    <Route
                        path="*"
                        element={
                            <AdminDashboardPage
                                userData={userData}
                            />
                        }
                    />
                    <Route
                        path="global_prices"
                        element={
                            <AdminGlobalPricesPage
                                baseData={baseData}
                                userData={userData}
                            />
                        }
                    />
                    <Route
                        path="users_new"
                        element={
                            <AdminUsersListPage
                                userData={userData}
                            />
                        }
                    />
                    <Route
                        path="users_new/:user_id"
                        element={
                            <AdminUserMainPage
                                baseData={baseData}
                                userData={userData}
                            />
                        }
                    />
                    <Route
                        path="users_new/:user_id/:tab_name"
                        element={
                            <AdminUserMainPage
                                baseData={baseData}
                                userData={userData}
                            />
                        }
                    />
                    <Route
                        path="blocked_numbers"
                        element={
                            <AdminBlockedNumbersPage
                                baseData={baseData}
                                userData={userData}
                            />
                        }
                    />

                </Route>

                {/* === MAIN =================================== */}

                <Route 
                    path="*"
                    element={
                        <PageFrameMain
                            baseData={baseData}
                            userData={userData}
                            userManager={userManager}
                            orderData={orderData}
                            orderManager={orderManager}
                        />
                    }>

                    <Route
                        path="order_leads/*"
                        element={
                            <OrderLeadsPage
                                baseData={baseData}
                                userData={userData}
                                userManager={userManager}
                                orderData={orderData}
                                orderManager={orderManager} />
                        }
                    />

                    <Route
                        path="populate_file"
                        element={
                            <PopulateFileUploadPage
                                baseData={baseData}
                                userData={userData} />
                        }
                    />

                    <Route
                        path="populate_file/:populate_file_id/*"
                        element={
                            <PopulateFileOrderPage
                                baseData={baseData}
                                userData={userData} />
                        }
                    />

                    <Route
                        path="quick_guide"
                        element={
                            <QuickGuidePage />
                        }
                    />

                    <Route
                        path="my_files"
                        element={
                            <MyFilesPage
                                baseData={baseData}
                                userData={userData}
                                orderManager={orderManager}
                            />
                        }
                    />

                    <Route
                        path="my_target_groups"
                        element={
                            <TargetGroupListPage
                                baseData={baseData}
                                userData={userData} />
                        }
                    />

                    <Route
                        path="my_target_groups/:target_group_id"
                        element={
                            <TargetGroupEditPage
                                baseData={baseData}
                                userData={userData} />
                        }
                    />

                    <Route
                        path="my_target_groups/:target_group_id/:tab_id"
                        element={
                            <TargetGroupEditPage
                                baseData={baseData}
                                userData={userData} />
                        }
                    />

                    <Route
                        path="my_user"
                        element={
                            <MyUserMainPage
                                baseData={baseData}
                                userData={userData}
                                userManager={userManager} />
                        }
                    />

                    <Route
                        path="my_user/:tab_name"
                        element={
                            <MyUserMainPage
                                baseData={baseData}
                                userData={userData}
                                userManager={userManager} />
                        }
                    />

                    <Route
                        path="create_stopfile"
                        element={
                            <CreateStopfilePage
                                userData={userData}
                            />
                        }
                    />

                    <Route
                        path="*"
                        element={
                            <Navigate to="/order_leads" replace />
                        }
                />

                </Route>
                
            </Routes>
        </BrowserRouter>
    );
}