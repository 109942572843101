import Axios from 'axios';
import { API_URL } from '../constants/Constants';

// TODO ERIK ta bort skiten!!

const baseUrl = `${process.env.NODE_ENV === 'production' ? API_URL : API_URL}`;
Axios.defaults.withCredentials = true;
export const POST = (url, data, config) =>
  Axios.post(`${baseUrl}${url}`, data, {
    'Access-Control-Allow-Origin': baseUrl,
    withCredentials: true,
    ...(config || '')
  });
export const GET = (url, data) =>
  Axios.get(`${baseUrl}${url}`, {}, { 'Access-Control-Allow-Origin': baseUrl, withCredentials: true });
export const PATCH = (url, data) =>
  Axios.patch(`${baseUrl}${url}`, data, { 'Access-Control-Allow-Origin': baseUrl, withCredentials: true });
export const DELETE = (url, data) =>
  Axios.delete(`${baseUrl}${url}`, data, { 'Access-Control-Allow-Origin': baseUrl, withCredentials: true });
export const PUT = (url, data) =>
  Axios.put(`${baseUrl}${url}`, data, { 'Access-Control-Allow-Origin': baseUrl, withCredentials: true });
