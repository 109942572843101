import React, { useState } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import { Formik } from 'formik';
import TextFieldComponent from '../../../components/TextFieldComponent';
import { BackToLoginButtonComponent } from '../../../components/BackToLoginButtonComponent';
import ButtonComponent from '../../../components/ButtonComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import makeT from '../../../translations/t.js';
import { useIntl } from 'react-intl';
import { errorDescription, errorMaxDescription, required } from '../../../functions/validationFunctions';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { USER_REGISTER } from '../../../ApiRoutes';
import { POST } from '../../../functions/axiosSending';

export const RegisterPage = () => {
  const intl = useIntl();
  const T = makeT(intl);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [submit, setSubmit] = useState(false);
  const navigate = useNavigate();
  const action = (key) => (
    <Button
      onClick={() => {
        closeSnackbar(key);
      }}
    >
      {'Ok'}
    </Button>
  );
  const [repeatPassword, setRepeatPassword] = useState(false);
  const getValidationSchema = (values) => {
    return Yup.object().shape({
      email: Yup.string()
        .email(T('check_email', 'form_validation'))
        .required(required(T, T('register.email_required')))
        .min(2, errorDescription(T, T('email_validation', 'form_validation')))
        .max(80, errorMaxDescription(T, T('email_validation', 'form_validation'))),
      password: Yup.string()
        .min(6, T('register.password_short'))
        .required(required(T, T('register.password_required'), 'e'))
        .max(128, errorMaxDescription(T, T('password_validation', 'register')))
    });
  };
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box mr={5} className="fixed-element padding-large-top">
          <Typography variant="h4">{'Skapa konto'}</Typography>
          <Box mt={2}>
            <Typography variant="h6" className="light-text">
              {'Ange en giltig epost adress samt ett telefonnummer. Du får en länk till angiven epost för verifiering.'}
            </Typography>
          </Box>
      </Box>

      <Paper style={{padding: '20px'}}>
      <Formik
        initialValues={{
          email: '',
          password: '',
          phoneNumber: '',
          repeatPassword: ''
        }}
        validationSchema={getValidationSchema('values')}
        onSubmit={(values) => {
          setSubmit(true);
          const data = {
            email: values.email,
            password: values.password,
            phone_number: values.phoneNumber
          };
          POST(USER_REGISTER, data)
            .then((success) => {
              setSubmit(false);
              navigate('/account/register/success');
            })
            .catch((error) => {
              setSubmit(false);
              enqueueSnackbar(T('register_error', 'errors'), { action, variant: 'error' });
            });
        }}
      >
        {(formikProps) => (
          <form onSubmit={formikProps.handleSubmit} className="register-form">
            <TextFieldComponent
              id="email"
              formikProps={formikProps}
              type="email"
              label={T('email', 'register')}
              fullWidth
            />
            <TextFieldComponent
              id="phoneNumber"
              formikProps={formikProps}
              type="tel"
              label={T('phone_number', 'register')}
              fullWidth
            />
            <TextFieldComponent
              id="password"
              formikProps={formikProps}
              type="password"
              label={T('password', 'register')}
              fullWidth
            />
            <TextFieldComponent
              id="repeatPassword"
              formikProps={formikProps}
              type="password"
              label={T('repeat_password', 'register')}
              onBlur={() => setRepeatPassword(formikProps.values['password'] !== formikProps.values['repeatPassword'])}
              fullWidth
              customHelperText={repeatPassword ? T('do_not_agree_with_password', 'form_validation') : ''}
            />
            <Box mt={2} display="flex">
              <ButtonComponent type="submit" disabled={submit} title={T('register', 'buttons')} />
              <BackToLoginButtonComponent />
            </Box>
          </form>
        )}
      </Formik>
      {submit ? <LoaderComponent /> : ''}
      </Paper>
    </Box>
  );
};
